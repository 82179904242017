import * as React from 'react';
import { Link, graphql } from 'gatsby';

// components
import Seo from '../components/SEO';

// utils
import { renderSwitch } from '../utils/Render';

const DefaultPage = ({ data: { pages, page }, location }) => {
    const { seo } = page;
    const keywords = [];
    if (seo?.keywords) {
        seo.keywords.forEach(keyword => {
            keywords.push(keyword.keyword);
        });
    }
    const content = page.contentMatrix;

    let previous;
    let next;

    if (page.uri.includes('projects-and-events/') && page.uri !== 'projects-and-events/all') {
        let projects = pages.nodes;
        projects = projects.filter(proj => proj.level !== 1);
        projects = projects
            .sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
            .reverse();
        const index = projects.findIndex(project => project.eventDate === page.eventDate);
        previous = projects[index - 1];
        next = projects[index + 1];
    }

    const projectHeader =
        page.uri.includes('projects-and-events/') && page.uri !== 'projects-and-events/all' ? (
            <>
                <div className="flex justify-between" style={{ margin: '32px 0 12px' }}>
                    {previous ? (
                        <Link className="arrow arrow--left" to={`/${previous.uri}`}>
                            <p className="text-base" style={{ fontWeight: 600 }}>
                                Previous
                            </p>
                        </Link>
                    ) : (
                        <div />
                    )}
                    {next ? (
                        <Link className="arrow arrow--right" to={`/${next.uri}`}>
                            <p className="text-base" style={{ fontWeight: 600 }}>
                                Next
                            </p>
                        </Link>
                    ) : (
                        <div />
                    )}
                </div>
            </>
        ) : null;

    return (
        <>
            <Seo
                title={seo?.title}
                canonical={seo?.advanced.canonical}
                description={seo?.description}
                facebookImage={seo?.social.facebook.image?.url}
                keywords={keywords}
                robots={seo?.advanced.robots}
                twitterImage={seo?.social.twitter.image?.url}
                modifiedTime={page.dateUpdated}
                url={location.href}
            />
            {projectHeader}
            <div
                className={`spacing--small ${
                    page.uri.includes('projects-and-events/') &&
                    page.uri !== 'projects-and-events/all'
                        ? 'border-top border-top--dashed'
                        : null
                }`}>
                {content?.map((block, i) => renderSwitch(block, i))}
            </div>
        </>
    );
};

export const query = graphql`
    query ($id: IDQueryOperatorInput) {
        pages: allCraftProjectsAndEventsDefaultEntry(filter: { enabled: { eq: true } }) {
            nodes {
                level
                uri
                ... on Craft_projectsAndEvents_default_Entry {
                    url
                    eventDate
                }
            }
        }
        page: craftEntryInterface(remoteId: $id) {
            title
            uri
            id: remoteId
            ... on Craft_pages_default_Entry {
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                    }
                }
                dateUpdated
                contentMatrix {
                    ... on Craft_contentMatrix_richTextBlock_BlockType {
                        initialText
                        richText
                        typeHandle
                        actsAsHeader
                        linkToEntry {
                            title
                            url
                            uri
                        }
                    }
                    ... on Craft_contentMatrix_excerptBlock_BlockType {
                        excerpt
                        typeHandle
                    }
                    ... on Craft_contentMatrix_productsBlock_BlockType {
                        typeHandle
                        related
                        productsList {
                            ... on Craft_shop_product_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                plainText
                                multipleImages {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_relatedEventsBlock_BlockType {
                        typeHandle
                        eventsList {
                            ... on Craft_projectsAndEvents_default_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                eventDate
                                plainText
                                singleImage {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_textAndImageBlock_BlockType {
                        blockCopy
                        blockImage {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_singleMedia_BlockType {
                        singleAsset {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_twoUpImageBlock_BlockType {
                        typeHandle
                        assets {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                    }
                    ... on Craft_contentMatrix_imageCarousel_BlockType {
                        typeHandle
                        carouselImages {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_linkBlocks_BlockType {
                        typeHandle
                        linkItem {
                            ... on Craft_linkItem_BlockType {
                                richText
                                buttonCopy
                                buttonUrl
                            }
                        }
                    }
                    ... on Craft_contentMatrix_accordionBlock_BlockType {
                        typeHandle
                        accordionItem {
                            ... on Craft_accordionItem_BlockType {
                                accordionLabel
                                accordionContent
                            }
                        }
                    }
                    ... on Craft_contentMatrix_projectList_BlockType {
                        showEvents
                        typeHandle
                    }
                    ... on Craft_contentMatrix_newsletterSignup_BlockType {
                        showNewsletter
                        typeHandle
                    }
                    ... on Craft_contentMatrix_giveCloudForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_donationForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_dynamicButton_BlockType {
                        typeHandle
                        buttonCopy
                        buttonLink
                        position
                    }
                    ... on Craft_contentMatrix_videosEmbedBlock_BlockType {
                        typeHandle
                        videoLinks {
                            ... on Craft_videoLinks_BlockType {
                                videoLink
                            }
                        }
                    }
                }
            }
            ... on Craft_aboutUs_default_Entry {
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                    }
                }
                dateUpdated
                contentMatrix {
                    ... on Craft_contentMatrix_richTextBlock_BlockType {
                        initialText
                        richText
                        typeHandle
                        actsAsHeader
                        linkToEntry {
                            title
                            url
                            uri
                        }
                    }
                    ... on Craft_contentMatrix_excerptBlock_BlockType {
                        excerpt
                        typeHandle
                    }
                    ... on Craft_contentMatrix_productsBlock_BlockType {
                        typeHandle
                        related
                        productsList {
                            ... on Craft_shop_product_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                plainText
                                multipleImages {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_relatedEventsBlock_BlockType {
                        typeHandle
                        eventsList {
                            ... on Craft_projectsAndEvents_default_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                eventDate
                                plainText
                                singleImage {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_textAndImageBlock_BlockType {
                        blockCopy
                        blockImage {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_singleMedia_BlockType {
                        singleAsset {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_twoUpImageBlock_BlockType {
                        typeHandle
                        assets {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                    }
                    ... on Craft_contentMatrix_imageCarousel_BlockType {
                        typeHandle
                        carouselImages {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_linkBlocks_BlockType {
                        typeHandle
                        linkItem {
                            ... on Craft_linkItem_BlockType {
                                richText
                                buttonCopy
                                buttonUrl
                            }
                        }
                    }
                    ... on Craft_contentMatrix_accordionBlock_BlockType {
                        typeHandle
                        accordionItem {
                            ... on Craft_accordionItem_BlockType {
                                accordionLabel
                                accordionContent
                            }
                        }
                    }
                    ... on Craft_contentMatrix_projectList_BlockType {
                        showEvents
                        typeHandle
                    }
                    ... on Craft_contentMatrix_newsletterSignup_BlockType {
                        showNewsletter
                        typeHandle
                    }
                    ... on Craft_contentMatrix_giveCloudForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_donationForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_dynamicButton_BlockType {
                        typeHandle
                        buttonCopy
                        buttonLink
                        position
                    }
                    ... on Craft_contentMatrix_videosEmbedBlock_BlockType {
                        typeHandle
                        videoLinks {
                            ... on Craft_videoLinks_BlockType {
                                videoLink
                            }
                        }
                    }
                }
            }
            ... on Craft_education_default_Entry {
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                    }
                }
                dateUpdated
                contentMatrix {
                    ... on Craft_contentMatrix_richTextBlock_BlockType {
                        initialText
                        richText
                        typeHandle
                        actsAsHeader
                        linkToEntry {
                            title
                            url
                            uri
                        }
                    }
                    ... on Craft_contentMatrix_excerptBlock_BlockType {
                        excerpt
                        typeHandle
                    }
                    ... on Craft_contentMatrix_productsBlock_BlockType {
                        typeHandle
                        related
                        productsList {
                            ... on Craft_shop_product_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                plainText
                                multipleImages {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_relatedEventsBlock_BlockType {
                        typeHandle
                        eventsList {
                            ... on Craft_projectsAndEvents_default_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                eventDate
                                plainText
                                singleImage {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_textAndImageBlock_BlockType {
                        blockCopy
                        blockImage {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_singleMedia_BlockType {
                        singleAsset {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_twoUpImageBlock_BlockType {
                        typeHandle
                        assets {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                    }
                    ... on Craft_contentMatrix_imageCarousel_BlockType {
                        typeHandle
                        carouselImages {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_linkBlocks_BlockType {
                        typeHandle
                        linkItem {
                            ... on Craft_linkItem_BlockType {
                                richText
                                buttonCopy
                                buttonUrl
                            }
                        }
                    }
                    ... on Craft_contentMatrix_accordionBlock_BlockType {
                        typeHandle
                        accordionItem {
                            ... on Craft_accordionItem_BlockType {
                                accordionLabel
                                accordionContent
                            }
                        }
                    }
                    ... on Craft_contentMatrix_projectList_BlockType {
                        showEvents
                        typeHandle
                    }
                    ... on Craft_contentMatrix_newsletterSignup_BlockType {
                        showNewsletter
                        typeHandle
                    }
                    ... on Craft_contentMatrix_giveCloudForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_donationForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_dynamicButton_BlockType {
                        typeHandle
                        buttonCopy
                        buttonLink
                        position
                    }
                    ... on Craft_contentMatrix_videosEmbedBlock_BlockType {
                        typeHandle
                        videoLinks {
                            ... on Craft_videoLinks_BlockType {
                                videoLink
                            }
                        }
                    }
                }
            }
            ... on Craft_projectsAndEvents_default_Entry {
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                    }
                }
                dateUpdated
                title
                eventDate
                plainText
                richTextStandard
                contentMatrix {
                    ... on Craft_contentMatrix_richTextBlock_BlockType {
                        initialText
                        richText
                        typeHandle
                        actsAsHeader
                        linkToEntry {
                            title
                            url
                            uri
                        }
                    }
                    ... on Craft_contentMatrix_excerptBlock_BlockType {
                        excerpt
                        typeHandle
                    }
                    ... on Craft_contentMatrix_productsBlock_BlockType {
                        typeHandle
                        related
                        productsList {
                            ... on Craft_shop_product_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                plainText
                                multipleImages {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_relatedEventsBlock_BlockType {
                        typeHandle
                        eventsList {
                            ... on Craft_projectsAndEvents_default_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                eventDate
                                plainText
                                singleImage {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_textAndImageBlock_BlockType {
                        blockCopy
                        blockImage {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_singleMedia_BlockType {
                        singleAsset {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_twoUpImageBlock_BlockType {
                        typeHandle
                        assets {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                    }
                    # ... on Craft_contentMatrix_imageCarousel_BlockType {
                    #     typeHandle
                    #     carouselImages {
                    #         ... on Craft_images_Asset {
                    #             url
                    #             title
                    #             imageFile {
                    #                 childImageSharp {
                    #                     gatsbyImageData
                    #                 }
                    #             }
                    #         }
                    #     }
                    # }
                    ... on Craft_contentMatrix_linkBlocks_BlockType {
                        typeHandle
                        linkItem {
                            ... on Craft_linkItem_BlockType {
                                richText
                                buttonCopy
                                buttonUrl
                            }
                        }
                    }
                    ... on Craft_contentMatrix_accordionBlock_BlockType {
                        typeHandle
                        accordionItem {
                            ... on Craft_accordionItem_BlockType {
                                accordionLabel
                                accordionContent
                            }
                        }
                    }
                    ... on Craft_contentMatrix_projectList_BlockType {
                        showEvents
                        typeHandle
                    }
                    ... on Craft_contentMatrix_newsletterSignup_BlockType {
                        showNewsletter
                        typeHandle
                    }
                    ... on Craft_contentMatrix_giveCloudForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_donationForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_dynamicButton_BlockType {
                        typeHandle
                        buttonCopy
                        buttonLink
                        position
                    }
                    ... on Craft_contentMatrix_videosEmbedBlock_BlockType {
                        typeHandle
                        videoLinks {
                            ... on Craft_videoLinks_BlockType {
                                videoLink
                            }
                        }
                    }
                }
            }
            ... on Craft_shop_default_Entry {
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    id
                                    url
                                }
                            }
                        }
                    }
                }
                dateUpdated
                contentMatrix {
                    ... on Craft_contentMatrix_richTextBlock_BlockType {
                        initialText
                        richText
                        typeHandle
                        actsAsHeader
                        linkToEntry {
                            title
                            url
                            uri
                        }
                    }
                    ... on Craft_contentMatrix_excerptBlock_BlockType {
                        excerpt
                        typeHandle
                    }
                    ... on Craft_contentMatrix_productsBlock_BlockType {
                        typeHandle
                        related
                        productsList {
                            ... on Craft_shop_product_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                plainText
                                multipleImages {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_relatedEventsBlock_BlockType {
                        typeHandle
                        eventsList {
                            ... on Craft_projectsAndEvents_default_Entry {
                                id: remoteId
                                title
                                uri
                                typeHandle
                                eventDate
                                plainText
                                singleImage {
                                    url
                                    title
                                    ... on Craft_images_Asset {
                                        url
                                        title
                                        imageFile {
                                            childImageSharp {
                                                gatsbyImageData(layout: CONSTRAINED)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_textAndImageBlock_BlockType {
                        blockCopy
                        blockImage {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_singleMedia_BlockType {
                        singleAsset {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                        typeHandle
                    }
                    ... on Craft_contentMatrix_twoUpImageBlock_BlockType {
                        typeHandle
                        assets {
                            ... on Craft_images_Asset {
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                                url
                                title
                            }
                        }
                    }
                    ... on Craft_contentMatrix_imageCarousel_BlockType {
                        typeHandle
                        carouselImages {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                    ... on Craft_contentMatrix_linkBlocks_BlockType {
                        typeHandle
                        linkItem {
                            ... on Craft_linkItem_BlockType {
                                richText
                                buttonCopy
                                buttonUrl
                            }
                        }
                    }
                    ... on Craft_contentMatrix_accordionBlock_BlockType {
                        typeHandle
                        accordionItem {
                            ... on Craft_accordionItem_BlockType {
                                accordionLabel
                                accordionContent
                            }
                        }
                    }
                    ... on Craft_contentMatrix_projectList_BlockType {
                        showEvents
                        typeHandle
                    }
                    ... on Craft_contentMatrix_newsletterSignup_BlockType {
                        showNewsletter
                        typeHandle
                    }
                    ... on Craft_contentMatrix_giveCloudForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_donationForm_BlockType {
                        typeHandle
                        showForm
                    }
                    ... on Craft_contentMatrix_dynamicButton_BlockType {
                        typeHandle
                        buttonCopy
                        buttonLink
                        position
                    }
                    ... on Craft_contentMatrix_videosEmbedBlock_BlockType {
                        typeHandle
                        videoLinks {
                            ... on Craft_videoLinks_BlockType {
                                videoLink
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default DefaultPage;
